import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { VehicleRecord } from "../../../types/dsv/audit";
import { Utilities } from "../../../utilities";
import { GenericDataGridFilterable, PreDefinedFilterModel } from "../../base/dataGrid/genericDataGridFilterable";
import { AuditDetailsDataGridToolbar } from "./auditDetailsDataGridToolbar";

type AuditDetailsDataGridProps = {
    vehicleAuditId: number
    rows: VehicleRecord[]
}

type MappedRow = {
    id: number
    vehicleAuditId: number
    stockNumber: string
    vin: string
    year: number
    make: string
    model: string
    color: string
    location: string
    lastScannedDate: string
    lastScannedLot: string
    availability: string
    status: string
    newUsed: string
    type: "unscanned" | "scanned" | "unexpected" | "unknown"
}
    
const columns: GridColDef[] = [
    {
        field: "id",
        flex: 1,
        headerName: "ID",
        hideable: true
    },
    {
        field: "newUsed",
        flex: 0.5,
        headerName: "Type",
        hideable: false,
        valueFormatter: (value) => {
            switch (value) {
                case "N":
                    return "New";
                case "U":
                    return "Used";
                default:
                    return "";
            }
        }
    },
    {
        field: "availability",
        flex: 0.5,
        headerName: "Availability",
        hideable: false
    },
    {
        field: "vehicleAuditId",
        flex: 1,
        headerName: "Audit ID",
        hideable: true
    },
    {
        field: "stockNumber",
        flex: 0.75,
        headerName: "Stock #",
        hideable: false
    },
    {
        field: "vin",
        flex: 1.5,
        headerName: "VIN",
        hideable: false
    },
    {
        field: "year",
        flex: 0.5,
        headerName: "Year",
        hideable: false
    },
    {
        field: "make",
        flex: 0.75,
        headerName: "Make",
        hideable: false
    },
    {
        field: "model",
        flex: 0.75,
        headerName: "Model",
        hideable: false
    },
    {
        field: "color",
        flex: 1,
        headerName: "Color",
        hideable: false
    },
    {
        field: "lastScannedDate",
        flex: 1.25,
        headerName: "Last Scanned Date",
        hideable: false,
        renderCell: (params) => {
            if (!params?.value) {
                return (<span></span>);
            }

            const date = new Date(params.value);
            return date.toLocaleDateString(undefined, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            });
        },
        sortComparator: (dateStr1: string, dateStr2: string): number => {
            const date1 = dateStr1 ? new Date(dateStr1).getTime() : 0;
            const date2 = dateStr2 ? new Date(dateStr2).getTime() : 0;
            return date1 - date2;
        }
    },
    {
        field: "lastScannedLot",
        flex: 1,
        headerName: "Last Scanned Lot",
        hideable: false
    },
    {
        field: "location",
        flex: 1,
        headerName: "Location",
        hideable: false
    },
    {
        field: "status",
        flex: 0.5,
        headerName: "Status",
        hideable: true   
    },
    {
        field: "type",
        flex: 1,
        headerName: "Type",
        hideable: true
    }
];

const predefinedFilters: PreDefinedFilterModel[] = [
    {
        label: "Not Scanned",
        filterModel: {
            items: [
                { id: 1, field: "type", operator: "equals", value: "unscanned" }
            ]
        }
    },
    {
        label: "Scanned",
        filterModel:{
            items: [
                { id: 2, field: "type", operator: "equals", value: "scanned" }
            ]
        }
    },
    {
        label: "Unexpected",
        filterModel: {
            items: [
                { id: 3, field: "type", operator: "equals", value: "unexpected" }
            ]
        }
    },
    {
        label: "Unknown",
        filterModel: {
            items: [
                { id: 4, field: "type", operator: "equals", value: "unknown" }
            ]
        }
    },
    {
        label: "All",
        filterModel: {
            items: []
        }
    }
];

function AuditDetailsDataGrid(props: AuditDetailsDataGridProps): JSX.Element {
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        if (!props.rows) {
            return;
        }
        
        setMappedRows(props.rows.map((value: VehicleRecord, index: number): MappedRow => {
            return {
                id: index,
                vehicleAuditId: value.vehicleAuditId,
                stockNumber: value.stockNumber,
                vin: value.vin,
                year: value.vehicleYear,
                make: value.vehicleMake,
                model: value.vehicleModel,
                color: value.color,
                location: value.scannedLocation,
                lastScannedDate: Utilities.getIsoDateStringFromLastScannedLocation(value.scannedLocation),
                lastScannedLot: Utilities.getLotStringFromLastScannedLocation(value.scannedLocation),
                availability: value.availability,
                status: value.status,
                newUsed: value.newUsed,
                type: value.identified
            };
        }));
    }, [props]);

    return (
        <GenericDataGridFilterable
            columns={columns}
            filterModels={predefinedFilters}
            columnVisibilityModel={{
                id: false,
                vehicleAuditId: false,
                status: false,
                type: false
            }}
            initialStatePagination={{
                page: 0,
                pageSize: 100
            }}
            initialStateSorting={{
                sortModel: [{ field: "lastScannedDate", sort: "desc" }]
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            rows={mappedRows}
            slots={{ toolbar: AuditDetailsDataGridToolbar }}
            />
    );
}

export { AuditDetailsDataGrid }