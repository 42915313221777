import { Stack, Typography } from "@mui/material";
import { PrinterSetup } from "./printer/printerSetup";
import { BackToAudits } from "../audit/backToAuditsButton";

function Setup(): JSX.Element {
    return (
        <Stack
            direction="column"
            spacing={5}
            >
            <Stack
                direction="column"
                spacing={1}
                >
                <BackToAudits />
                <Typography
                    variant="h5"
                    sx={{
                        flexGrow: 1
                    }}
                    >
                    Printer
                </Typography>
            </Stack>
            <PrinterSetup />
        </Stack>
    );
}

export { Setup }