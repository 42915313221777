import React from "react";
import { Button } from "@mui/material";
import { GenericModal } from "../../../base/modal";
import { CreateAuditForm, CreateAuditFormValues } from "./createAuditForm";
import { useApiService } from "../../../base/providers";
import { useNavigate } from "react-router-dom";
import { CreateAuditResponse } from "../../../../types/dsp/audits";

type CreateAuditButtonProps = {
    getAllAuditsCallback: () => void
}

function CreateAuditButton(props: CreateAuditButtonProps): JSX.Element {
    const navigate = useNavigate();
    const { apiService } = useApiService();
    const [showModal, setShowModal] = React.useState(false);
    const [auditId, setAuditId] = React.useState<number | undefined>(undefined);

    const hideModalCallback = React.useCallback(() => {
        setShowModal(false);
    }, []);

    const submitCreateAuditCallback = React.useCallback((formValues: CreateAuditFormValues) => {
        apiService.dsp.createAudit(formValues.auditName)
            .then((response: CreateAuditResponse) => setAuditId(response.auditId))
            .then(() => props.getAllAuditsCallback())
            .then(() => hideModalCallback())
            .catch((err) => console.error("Unable to create audit", err));
    }, [apiService, props, hideModalCallback]);

    React.useEffect(() => {
        if (!auditId) {
            return;
        }

        navigate(`/parts/audit/selection/${auditId}`);
    }, [auditId, navigate]);

    const onClickCreateAudit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        
        setShowModal(true);
    }

    return(
        <>
            <Button
                color="primary"
                onClick={onClickCreateAudit}
                variant="contained"
                >
                Start Audit
            </Button>
            <GenericModal
                showModal={showModal}
                hideModalCallback={hideModalCallback}
                dialogTitle="Create Audit"
                >
                <CreateAuditForm
                    clickSubmitCallback={submitCreateAuditCallback}
                    />
            </GenericModal>
        </>
        
    );
}

export { CreateAuditButton }