import { Button } from "@mui/material";

type FinishAuditButtonProps = {
    isDisabled: boolean
}

function FinishAuditButton(props: FinishAuditButtonProps): JSX.Element {
    return (
        <Button
            color="primary"
            disabled={props.isDisabled}
            variant="contained"
            >
            Finish Audit
        </Button>
    );
}

export { FinishAuditButton }