import { Box, Button, Chip, Stack, Typography } from "@mui/material";

type SelectionNavigationViewProps = {
    binsLength: number
    isReadOnly: boolean
    selectedBinsLength: number
    onClickSelectAllCallback: () => void
}

function SelectionNavigationView(props: SelectionNavigationViewProps): JSX.Element {
    const onClickSelectAll = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.onClickSelectAllCallback();
    }

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            >
            <Box flex="1">
                <Button>
                    Bins List&nbsp;
                    <Chip
                        label={props.binsLength}
                        size="small"
                        sx={{
                            marginLeft: 1
                        }}
                        />
                </Button>
                <Button>
                    Parts List&nbsp;
                    <Chip
                        label="0"
                        size="small"
                        sx={{
                            marginLeft: 1
                        }}
                        />
                </Button>
            </Box>
            <Typography
                variant="body2"
                style={{
                    fontWeight: "bold"
                }}
                >
                Parts/Bins added to Audit&nbsp;
                <Chip
                    label={props.selectedBinsLength}
                    size="small"
                    sx={{
                        marginLeft: 1
                    }}
                    />
            </Typography>
            <Button
                disabled={props.isReadOnly}
                onClick={onClickSelectAll}
                variant="contained"
                >
                Select All
            </Button>
        </Stack>
    );
}

export { SelectionNavigationView }