import { Button, Chip } from "@mui/material";
import { useApiService } from "../../../../../base/providers";
import { DspAuditBinSimple, DspAuditVariance } from "types/dsp/audits";

type RequestRescanButtonProps = {
    reloadDataCallback: () => void
    rescanVariances: DspAuditVariance[]
}

function RequestRescanButton(props: RequestRescanButtonProps): JSX.Element {
    const { apiService } = useApiService();

    const onClickSelectRescans = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        
        const rescanVarianceBinIds = props.rescanVariances
            .flatMap((value: DspAuditVariance) => value.bins)
            .flatMap((value: DspAuditBinSimple) => value.binId);
            
        apiService.dsp.flagBinsForRescan(rescanVarianceBinIds)
            .then(() => props.reloadDataCallback())
            .catch((err) => console.error("Unable to flag bins for rescan", err));
    }

    return (
        <Button
            onClick={onClickSelectRescans}
            variant="outlined"
            >
            <Chip label={props.rescanVariances.length} />&nbsp;Re-Scan
        </Button>
    );
}

export { RequestRescanButton }