import { AuthProvider } from './components/base';
import { ApiServiceProvider } from './components/base/providers';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from './appRouter';

function App(): JSX.Element {

    return (
        <AuthProvider>
            <ApiServiceProvider>
                <RouterProvider router={appRouter} />
            </ApiServiceProvider>
        </AuthProvider>
    );
}

export { App };
