import { Button, Chip } from "@mui/material";
import { AuditVarianceTabType } from "../../varianceTypes";

type RescanTabButtonProps = {
    auditVarianceCount: number
    setAuditVarianceTypeCallback: (auditVarianceType: AuditVarianceTabType) => void
}

function RescanTabButton(props: RescanTabButtonProps) {
    const onClickRescanTabButton = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.setAuditVarianceTypeCallback("rescans");
    }

    return (
        <Button onClick={onClickRescanTabButton}>
            Pending Rescans&nbsp;
            <Chip
                color="primary"
                label={props.auditVarianceCount}
                />
        </Button>
    );
}

export { RescanTabButton }