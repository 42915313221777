import { Box } from "@mui/material";

type DataSelectionViewProps = {
    children: React.ReactNode[]
}

function DataSelectionView(props: DataSelectionViewProps): JSX.Element {
    return (
        <Box sx={{ flex: 1 }}>
            {props.children}
        </Box>
    );
}

export { DataSelectionView }