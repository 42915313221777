import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { useApiService } from "../../../base/providers";
import { AuditDetailsDataGrid } from "./auditDetailsDataGrid";
import { GetDspAuditDetailsResponse } from "../../../../types/dsp/audits";
import { AuditDetailsTitleRowView } from "./auditDetailsTitleRowView";

function AuditDetailsView(): JSX.Element {
    const { auditId } = useParams();
    const navigate = useNavigate();
    const { apiService } = useApiService();
    const [auditDetails, setAuditDetails] = React.useState<GetDspAuditDetailsResponse>();
    const [selectedBins, setSelectedBins] = React.useState<Set<string>>(new Set<string>());

    const onChangeIsRowSelectedCallback = React.useCallback((binName: string) => {
        setSelectedBins((prevSet) => {
            const newSelected = new Set(prevSet);

            if (newSelected.has(binName)) {
                newSelected.delete(binName);
            } else {
                newSelected.add(binName);
            }

            return newSelected;
        });
    }, []);

    React.useEffect(() => {
        if (!auditId) {
            return;
        }

        apiService.dsp.getAuditDetails(auditId)
            .then((response: GetDspAuditDetailsResponse) => setAuditDetails(response))
            .catch((err) => console.error("Unable to get audit details", err));
    }, [apiService, auditId]);

    const onClickBackToAuditDetails = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate("/parts/audit");
    }

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Button
                onClick={onClickBackToAuditDetails}
                variant="text"
                sx={{
                    alignSelf: "start",
                    minWidth: "auto",
                    width: "fit-content"
                }}
                >
                &lt;&nbsp;Back to Audits
            </Button>
            <AuditDetailsTitleRowView
                auditName={auditDetails?.audit.auditName || ""}
                />
            <AuditDetailsDataGrid
                auditBins={auditDetails?.auditBins || []}
                onChangeIsRowSelectedCallback={onChangeIsRowSelectedCallback}
                selectedRows={selectedBins}
                />
        </Stack>
    );
}

export { AuditDetailsView }