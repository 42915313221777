import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { CancelAuditButton } from "./cancelAuditButton";
import { FinishAuditButton } from "./finishAuditButton";
import { ViewVarianceButton } from "./viewVarianceButton";

type AuditDetailsTitleRowViewProps = {
    auditName: string
}

function AuditDetailsTitleRowView(props: AuditDetailsTitleRowViewProps): JSX.Element {
    return (
        <Stack
            direction="row"
            spacing={2}
            >
            <Typography
                variant="h6"
                sx={{
                    flex: 1
                }}
                >
                {props.auditName}
            </Typography>
            <CancelAuditButton />
            <FinishAuditButton
                isDisabled={true}
                />
            <ViewVarianceButton />
        </Stack>
    );
}

export { AuditDetailsTitleRowView }