import React from "react";
import { GridCallbackDetails, GridColDef, GridColumnVisibilityModel, GridFilterModel, GridPaginationModel, GridSlotsComponent, GridSortingInitialState, useGridApiRef } from "@mui/x-data-grid";
import { GenericDataGrid } from "./genericDataGrid";
import { Button, Chip, Stack } from "@mui/material";

type PreDefinedFilterModel = {
    label: string
    filterModel: GridFilterModel
}

type GenericDataGridFilterableProps = {
    columns: GridColDef[]
    filterModels?: PreDefinedFilterModel[] | undefined
    columnVisibilityModel?: GridColumnVisibilityModel | undefined
    initialStatePagination?: Partial<GridPaginationModel> | undefined
    initialStateSorting?: GridSortingInitialState | undefined
    onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void | undefined
    pageSizeOptions?: number[] | undefined
    rows: any[]
    slots?: Partial<GridSlotsComponent> | undefined
}

function GenericDataGridFilterable(props: GenericDataGridFilterableProps): JSX.Element {
    const apiRef = useGridApiRef();
    const [predefinedFiltersRowCount, setPredefinedFiltersRowCount] = React.useState<number[]>([]);
    const [selectedFilter, setSelectedFilter] = React.useState<PreDefinedFilterModel | undefined>(undefined);
    const [filtersOptionsElements, setFiltersOptionsElements] = React.useState<JSX.Element[]>([]);

    const getFilteredRowsCountCallback = React.useCallback((filterModel: GridFilterModel) => {
        const { filteredRowsLookup } = apiRef.current.getFilterState(filterModel);
        return Object.keys(filteredRowsLookup).filter(
            (rowId) => filteredRowsLookup[rowId] === true,
        ).length;
    }, [apiRef]);

    React.useEffect(() => {
        if (props.rows.length === 0 || !props.filterModels) {
            return;
        }

        setPredefinedFiltersRowCount(
            props.filterModels.map((model: PreDefinedFilterModel) => getFilteredRowsCountCallback(model.filterModel))
        );
    }, [props, getFilteredRowsCountCallback]);

    React.useEffect(() => {
        if (props.rows.length === 0 || !props.filterModels) {
            return;
        }
        
        apiRef.current.setFilterModel(props.filterModels[0].filterModel);
        setSelectedFilter(props.filterModels[0]);
    }, [apiRef, predefinedFiltersRowCount, props]);

    React.useEffect(() => {
        if (!props.filterModels) {
            return;
        }

        setFiltersOptionsElements(props.filterModels.map((value: PreDefinedFilterModel, index: number): JSX.Element => {
            const count = predefinedFiltersRowCount[index];
            return (
                <Button
                    key={index}
                    onClick={() => {
                        apiRef.current.setFilterModel(value.filterModel);
                        setSelectedFilter(value);
                    }}
                    variant={selectedFilter?.label === value.label ? "outlined" : "text"}
                    >
                    {value.label} {
                        count !== undefined
                            ? <Chip
                                label={count}
                                size="small"
                                sx={{
                                    marginLeft: 1
                                }}
                                />
                            : <></>}
                </Button>
            );
        }))
    }, [apiRef, predefinedFiltersRowCount, props, selectedFilter]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Stack
                direction="row"
                spacing={2}
                >
                {filtersOptionsElements}
            </Stack>
            <GenericDataGrid
                apiRef={apiRef}
                columns={props.columns}
                columnVisibilityModel={props.columnVisibilityModel}
                initialStatePagination={props.initialStatePagination}
                initialStateSorting={props.initialStateSorting}
                onColumnVisibilityModelChange={props.onColumnVisibilityModelChange}
                pageSizeOptions={props.pageSizeOptions}
                rows={props.rows}
                slots={props.slots}
                />
        </Stack>
    );
}

export { GenericDataGridFilterable }
export type { PreDefinedFilterModel }