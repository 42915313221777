import React from "react";
import { useParams } from "react-router-dom";
import { DspAudit, GetDspAuditsResponse } from "../../../../types/dsp/audits";
import { useApiService } from "../../../base/providers";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { SelectionDataView } from "./selectionDataView";

type FormValues = {
    auditName: string
}

function BinSelectionView(): JSX.Element {
    const { auditId } = useParams();
    const { apiService } = useApiService();
    const [audit, setAudit] = React.useState<DspAudit | undefined>(undefined);
    const [formValues, setFormValues] = React.useState<FormValues>({
        auditName: ""
    });
    const [updatingAuditName, setUpdatingAuditName] = React.useState(false);
    
    React.useEffect(() => {
        apiService.dsp.getAudit(Number(auditId))
            .then((response: GetDspAuditsResponse) => setAudit(response.audits[0]))
            .catch((err) => console.error("Unable to get audit", err));
    }, [apiService, auditId]);

    React.useEffect(() => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                auditName: audit?.auditName || ""
            }
        });
    }, [audit]);

    React.useEffect(() => {
        if (!updatingAuditName) {
            return;
        }

        apiService.dsp.updateAuditName(Number(auditId), formValues.auditName)
            .then(() => setUpdatingAuditName(false))
            .catch((err) => {
                console.error("Unable to update audit name", err);
                setUpdatingAuditName(false);
            });
    }, [updatingAuditName, apiService, auditId, formValues])

    const onChangeAuditName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                auditName: e.target.value || ""
            }
        });
    }

    const onClickChange = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setUpdatingAuditName(true);
    }
    
    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Stack
                direction = "row"
                spacing={2}
                >
                <TextField
                    onChange={onChangeAuditName}
                    value={formValues.auditName}
                    />
                <Button
                    disabled={updatingAuditName}
                    onClick={onClickChange}
                    variant="contained"
                    >
                    Change    
                </Button>
            </Stack>
            <Typography variant="h6">Choose Bins/Parts</Typography>
            <SelectionDataView auditId={Number(auditId)} />
        </Stack>
    );
}

export { BinSelectionView }