import React from "react";
import { AuditVarianceTabType } from "../varianceTypes";
import { Stack } from "@mui/material";
import { AcceptPendingVarianceButton } from "./acceptPendingVarianceButton";
import { DspAuditVariance } from "types/dsp/audits";
import { RequestRescanButton } from "./requestRescanButton";

type OptionsResolverProps = {
    auditVarianceType: AuditVarianceTabType
    pendingVariances: DspAuditVariance[]
    reloadDataCallback: () => void
    rescanVariances: DspAuditVariance[]
}

function OptionsResolver(props: OptionsResolverProps): JSX.Element {
    const [component, setComponent] = React.useState<React.ReactNode>();

    React.useEffect(() => {
        setComponent(() => {
            switch (props.auditVarianceType) {
                case "pending":
                    return (
                        <AcceptPendingVarianceButton
                            key="accept-pending-variance"
                            pendingVariances={props.pendingVariances}
                            reloadDataCallback={props.reloadDataCallback}
                            />
                    );
                case "rescans":
                    return (
                        <RequestRescanButton
                            key="request-rescan"
                            reloadDataCallback={props.reloadDataCallback}
                            rescanVariances={props.rescanVariances}
                            />
                    );
                default: 
                    return (<></>);
            }
        });
    }, [props]);

    return (
        <Stack
            direction="row"
            spacing={2}
            >
            {component}
        </Stack>
    );
}

export { OptionsResolver }