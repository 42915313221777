import React from "react";
import { useApiService } from "../../../base/providers";
import { Stack } from "@mui/material";
import { BinSelectionDataGrid } from "./binSelectionDataGrid";
import { DspAuditBin, GetDspAuditTargetsResponse } from "../../../../types/dsp/audits";
import { SelectionNavigationView } from "./selectionNavigationView";
import { SelectionActionView } from "./selectionActionView";
import { useNavigate } from "react-router-dom";

type SelectionDataViewProps = {
    auditId: number
}

function SelectionDataView(props: SelectionDataViewProps): JSX.Element {
    const { apiService } = useApiService();
    const Navigate = useNavigate();
    const [bins, setBins] = React.useState<DspAuditBin[]>([]);
    const [selectedBins, setSelectedBins] = React.useState<Set<string>>(new Set<string>());
    const [isReadOnly, setIsReadOnly] = React.useState(false);

    const onChangeIsRowSelectedCallback = React.useCallback((binName: string): void => {
        setSelectedBins((prevSet) => {
            const newSelected = new Set(prevSet);

            if (newSelected.has(binName)) {
                newSelected.delete(binName);
            } else {
                newSelected.add(binName);
            }

            return newSelected;
        });
    }, []);

    const onClickSelectAllCallback = React.useCallback(() => {
        if (selectedBins.size < bins.length) {
            const binNames = bins.map(bin => bin.binName);
            setSelectedBins(new Set(binNames));
        } else {
            setSelectedBins(new Set());
        }
    }, [bins, selectedBins]);

    const onClickPrimaryActionCallback = React.useCallback(() => {
        if (!isReadOnly) {
            setIsReadOnly(true);
        } else {
            const includedBins = bins.filter((dspAuditBin: DspAuditBin) => selectedBins.has(dspAuditBin.binName))
            apiService.dsp.setAuditBins(props.auditId, includedBins)
                .then(() => Navigate("/parts"))
                .catch((err) => console.error("Unable to add bins to audit", err));
        }
    }, [isReadOnly, props.auditId, apiService, Navigate, bins, selectedBins])

    const onClickSecondaryActionCallback = React.useCallback(() => {
        if (isReadOnly) {
            setIsReadOnly(false);
        } else {
            apiService.dsp.deleteAudit(props.auditId)
                .then(() => Navigate("/parts"))
                .catch((err) => console.error("Unable to delete audit", err));
        }
    }, [isReadOnly, props.auditId, apiService, Navigate]);

    React.useEffect(() => {
        apiService.dsp.getAuditTargets()
            .then((auditTargets: GetDspAuditTargetsResponse) => setBins(auditTargets.bins))
            .catch((err) => console.error("Unable to get audit targets", err));
    }, [apiService]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <SelectionNavigationView
                binsLength={bins.length}
                isReadOnly={isReadOnly}
                selectedBinsLength={selectedBins.size}
                onClickSelectAllCallback={onClickSelectAllCallback}
                />
            <BinSelectionDataGrid
                bins={bins}
                isReadOnly={isReadOnly}
                selectedRows={selectedBins}
                onChangeIsRowSelectedCallback={onChangeIsRowSelectedCallback}
                />
            <SelectionActionView
                isReadOnly={isReadOnly}
                onClickPrimaryActionCallback={onClickPrimaryActionCallback}
                onClickSecondaryActionCallback={onClickSecondaryActionCallback}
                />
        </Stack>
    );
}

export { SelectionDataView }