import { Alert, Stack, Typography } from "@mui/material";
import { GenericModal } from "../../../../../base/modal";

type PendingVarianceModalProps = {
    showModal: boolean
    hideModalCallback: () => void
    onClickConfirmCallback: () => void
}

function PendingVarianceModal(props: PendingVarianceModalProps): JSX.Element {
    return (
        <GenericModal
            showModal={props.showModal}
            hideModalCallback={props.hideModalCallback}
            dialogTitle="Accept New Quantities?"
            showConfirmAction={true}
            confirmActionText="Submit to LightYear"
            confirmActionCallback={props.onClickConfirmCallback}
            >
            <Stack 
                direction="column"
                spacing={2}
                >
                <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="h6"
                    >
                    Are you sure you want to accept these new quantities?
                </Typography>
                <Alert severity="warning">
                    <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="body2"
                        >
                        You have chosen to override a quantity! This will be noted in the part changelog and audit reports.
                    </Typography>
                </Alert>
                <Typography variant="body1">The on-hand count for the part(s) will be updated in LightYear with the new quantity from this audit. This will also add an entry in the changelog for the part in LightYear</Typography>
            </Stack>
        </GenericModal>
    );
}

export { PendingVarianceModal }