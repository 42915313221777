import React from "react"
import { Checkbox, MenuItem, Select } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { DspAuditBin } from "../../../../types/dsp/audits"
import { GenericDataGrid } from "../../../base/dataGrid/genericDataGrid"
import { BinStatusResolver } from "./binStatusResolver"
import { Utilities } from "../../../../utilities"

type AuditDetailsDataGridProps = {
    auditBins: DspAuditBin[]
    selectedRows: Set<string>
    onChangeIsRowSelectedCallback: (binName: string) => void
}

type ColumnsParamsType = {
    selectedRows: Set<string>
    onChangeIsRowSelectedCallback: (binName: string) => void
}

type MappedRow = {
    id: number
    binName: string
    binStatus: number
    uniqueParts: number
    onHand: number
    totalValue: number
    timeToScan: number
    submitted: Date
    assigned: string
    isSelected: boolean
}

const columns = (columnsParams: ColumnsParamsType): GridColDef[] => [
    {
        field: "id",
        headerName: "ID",
        hideable: true,
        width: 90
    },
    {
        field: "binName",
        flex: 1,
        headerName: "Bin",
        hideable: false
    },
    {
        field: "binStatus",
        flex: 1,
        headerName: "Status",
        hideable: false,
        renderCell: (params) => (
            <BinStatusResolver status={params.value} />
        )
    },
    {
        field: "uniqueParts",
        flex: 1,
        headerName: "Unique Parts",
        hideable: false
    },
    {
        field: "onHand",
        flex: 1,
        headerName: "On Hand",
        hideable: false
    },
    {
        field: "totalValue",
        flex: 1,
        headerName: "Total Value",
        hideable: false,
        renderCell: (params) => (
            <span>{Utilities.displayAsCurrency(params.value)}</span>
        )
    },
    {
        field: "timeToScan",
        flex: 1,
        headerName: "Time To Scan",
        hideable: false,
        renderCell: (params) => {
            if (params.row.binStatus === 0) {
                return (<span>-</span>);
            }

            return (<span>{params.value}</span>);
        }
    },
    {
        field: "submitted",
        flex: 1,
        headerName: "Submitted",
        hideable: false,
        renderCell: (params) => {
            if (params.row.binStatus === 0) {
                return (<span>-</span>);
            }

            return (<span>{params.value.toLocaleString()}</span>);
        }
    },
    {
        field: "assigned",
        flex: 1.5,
        headerName: "Assigned",
        hideable: false,
        renderCell: (params) => {
            if (params.row.binStatus !== 0) {
                return (<></>);
            }

            return (
                <Select value={1}>
                    <MenuItem value={1}>Not Yet Implemented</MenuItem>
                </Select>
            )
        }
    },
    {
        field: "isSelected",
        flex: 0.5,
        headerName: "",
        hideable: false,
        renderCell: (params) => (
            <Checkbox
                checked={columnsParams.selectedRows.has(params.row.binName)}
                disabled={params.row.status !== 0}
                onChange={() => columnsParams.onChangeIsRowSelectedCallback(params.row.binName)}
                />
        )
    }
];

function AuditDetailsDataGrid(props: AuditDetailsDataGridProps): JSX.Element {
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);
    
    React.useEffect(() => {
        if (!props.auditBins) {
            return;
        }
        
        setMappedRows(props.auditBins.map((auditBin: DspAuditBin, index: number) => {
            return {
                id: index,
                binName: auditBin.binName,
                binStatus: auditBin.binStatus,
                uniqueParts: auditBin.uniqueParts,
                onHand: auditBin.totalParts,
                totalValue: auditBin.binDollarValue,
                timeToScan: 0,
                submitted: new Date(),
                assigned: "Not Yet Implemented",
                isSelected: false
            }
        }))
    }, [props.auditBins]);

    return (
        <GenericDataGrid
            columns={columns({
                selectedRows: props.selectedRows,
                onChangeIsRowSelectedCallback: props.onChangeIsRowSelectedCallback
            })}
            columnVisibilityModel={{
                id: false,
                type: false
            }}
            initialStatePagination={{
                page: 0,
                pageSize: 25
            }}
            initialStateSorting={{
                sortModel: [{ field: "binName", sort: "asc" }]
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            rows={mappedRows}
            />
    );
}

export { AuditDetailsDataGrid }