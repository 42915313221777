import React from "react";
import { AuditVarianceTabType } from "../varianceTypes";
import { DspAuditVariance } from "types/dsp/audits";
import { PendingVarianceDataGrid } from "./pending";
import { ReScanVarianceDataGrid } from "./rescan";

type VarianceDataGridResolverProps = {
    auditVariances: DspAuditVariance[]
    auditVarianceType: AuditVarianceTabType
    onChangeIsPendingRowSelectedCallback: (selectedLightyearIds: Set<number>) => void
    onChangeIsRescanRowSelectedCallback: (selectedLightyearIds: Set<number>) => void
    rescanVariances: DspAuditVariance[]
}

function VarianceDataGridResolver(props: VarianceDataGridResolverProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (props.auditVarianceType) {
                case "pending":
                    return (
                        <PendingVarianceDataGrid
                            auditVariances={props.auditVariances}
                            onChangeIsRowSelectedCallback={props.onChangeIsPendingRowSelectedCallback}
                            />
                    );
                case "rescans":
                    return (
                        <ReScanVarianceDataGrid
                            onChangeIsRowSelectedCallback={props.onChangeIsRescanRowSelectedCallback}
                            rescanVariances={props.auditVariances}
                            />
                    );
                default: 
                    return (<></>);
            }
        });
    }, [props]);

    return (component);
}

export { VarianceDataGridResolver }