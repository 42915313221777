import { AcceptPendingVarianceButton } from "./acceptPendingVarianceButton";
import { DataSelectionView } from "./dataSelectionView";
import { OptionsResolver } from "./optionsResolver";
import { PendingTabButton } from "./pendingTabButton";
import { RescanTabButton } from "./rescanTabButton";
import { Stack } from "@mui/material";
import { DspAuditVariance } from "types/dsp/audits";
import { AuditVarianceTabType } from "../varianceTypes";

type VarianceOptionsRowProps = {
    auditVariances: DspAuditVariance[]
    auditVarianceType: AuditVarianceTabType
    onChangeIsPendingRowSelectedCallback: (selectedLightyearIds: Set<number>) => void
    onChangeIsRescanRowSelectedCallback: (selectedLightyearIds: Set<number>) => void
    pendingVariances: DspAuditVariance[]
    reloadDataCallback: () => void
    rescanVariances: DspAuditVariance[]
    setAuditVarianceTypeCallback: (auditVarianceType: AuditVarianceTabType) => void
}


function VarianceOptionsRow(props: VarianceOptionsRowProps): JSX.Element {
    return (
        <Stack direction="row">
            <DataSelectionView>
                <PendingTabButton
                    key="pending-tab"
                    auditVarianceCount={props.auditVariances.length}
                    setAuditVarianceTypeCallback={props.setAuditVarianceTypeCallback}
                    />
                <RescanTabButton
                    key="rescan-tab"
                    auditVarianceCount={props.auditVariances.length}
                    setAuditVarianceTypeCallback={props.setAuditVarianceTypeCallback}
                    />
            </DataSelectionView>
            <OptionsResolver
                auditVarianceType={props.auditVarianceType}
                pendingVariances={props.pendingVariances}
                reloadDataCallback={props.reloadDataCallback}
                rescanVariances={props.rescanVariances}
                />
        </Stack>
    );
}

export { AcceptPendingVarianceButton, VarianceOptionsRow }