import { Stack, Typography } from "@mui/material";
import { NavButtons } from "../../base/navButtons";
import { PrintPartsLabelsButton } from "../print/printPartsLabelsButton";
import { OngoingAuditsResolver } from "./ongoingAuditsResolver";
import { CreateAuditButton } from "./createForm/createAuditButton";
import React from "react";
import { useApiService } from "../../base/providers";
import { DspAudit, GetDspAuditsResponse } from "../../../types/dsp/audits";

function OngoingAudits(): JSX.Element {
    const { apiService } = useApiService();
    const [audits, setAudits] = React.useState<DspAudit[]>([]);
    
    const getAllAuditsCallback = React.useCallback(() => {
        apiService.dsp.getAllAudits()
            .then((response: GetDspAuditsResponse) => setAudits(response.audits))
            .catch((err) => console.error("Unable to get audits", err));
    }, [apiService]);

    React.useEffect(() => {
        getAllAuditsCallback();
    }, [getAllAuditsCallback]);

    return (
        <Stack direction="column">
            <Stack direction="row">
                <Typography
                    variant="h6"
                    sx={{
                        flexGrow: 1
                    }}
                    >
                    Inventory Audits
                </Typography>
                <NavButtons
                    showSetup={true}
                    setupPath="/parts/setup"
                    >
                    <PrintPartsLabelsButton />
                    <CreateAuditButton
                        getAllAuditsCallback={getAllAuditsCallback}
                        />
                </NavButtons>
            </Stack>
            <OngoingAuditsResolver
                audits={audits}
                getAllAuditsCallback={getAllAuditsCallback}
                />
        </Stack>
    );
}

export { OngoingAudits }