import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

type AuditDetailsButtonProps = {
    auditName: string
    auditId: number
    disabled: boolean
}

function AuditDetailsButton(props: AuditDetailsButtonProps): JSX.Element {
    const navigate = useNavigate();
    const onClickAuditName = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate(`/parts/audit/${props.auditId}`);
    }

    return (
        <Button
            disabled={props.disabled}
            onClick={onClickAuditName}
            variant="text"
            >
            {props.auditName}
        </Button>
    );
}

export { AuditDetailsButton }