import React from "react";
import { GenericDataGrid } from "../../../base/dataGrid/genericDataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { DspAuditBin } from "../../../../types/dsp/audits";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { Utilities } from "../../../../utilities";

type MappedRow = DspAuditBin & {
    id: number
    lastAuditDate: string
    isSelected: boolean
}

type BinSelectionDataGridProps = {
    bins: DspAuditBin[]
    isReadOnly: boolean
    selectedRows: Set<string>
    onChangeIsRowSelectedCallback: (binName: string) => void
}

type BinSelectionDataGridVisibilityModel = {
    id: boolean
    auditor: boolean
}

function BinSelectionDataGrid(props: BinSelectionDataGridProps): JSX.Element {
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<BinSelectionDataGridVisibilityModel>({
        id: false,
        auditor: false
    });

    React.useEffect(() => {
        if (!props.bins || props.bins.length === 0) {
            return;
        }

        setMappedRows(props.bins
            .filter((dspAuditBin: DspAuditBin) => !props.isReadOnly || props.selectedRows.has(dspAuditBin.binName))
            .map((dspAuditBin: DspAuditBin, index: number) => {
                return {
                    id: index,
                    binName: dspAuditBin.binName,
                    binStatus: dspAuditBin.binStatus,
                    uniqueParts: dspAuditBin.uniqueParts,
                    totalParts: dspAuditBin.totalParts,
                    binDollarValue: dspAuditBin.binDollarValue,
                    lastAuditDate: "Not Yet Implemented",
                    isSelected: false
                };
            }));
    }, [props.bins, props.isReadOnly, props.selectedRows]);

    React.useEffect(() => {
        setColumnVisibilityModel((prevState) => {
            return {
                ...prevState,
                auditor: props.isReadOnly
            }
        });
    }, [props.isReadOnly]);

    const columns: GridColDef[] = [
        {
            field: "id",
            flex: 1,
            headerName: "ID",
            hideable: true
        },
        {
            field: "binName",
            flex: 1,
            headerName: "Bin"
        },
        {
            field: "uniqueParts",
            flex: 1,
            headerName: "Part Numbers"
        },
        {
            field: "totalParts",
            flex: 1,
            headerName: "Total Parts"
        },
        {
            field: "binDollarValue",
            flex: 1,
            headerName: "Dollar Value of Bin",
            renderCell: (params) => (
                <span>{Utilities.displayAsCurrency(params.value)}</span>
            )
        },
        {
            field: "lastAuditDate",
            flex: 1,
            headerName: "Last Audit Date"
        },
        {
            field: "auditor",
            flex: 1.25,
            headerName: "Auditor",
            hideable: true,
            renderCell: () => (
                <Select value={1}>
                    <MenuItem value={1}>Not Yet Implemented</MenuItem>
                </Select>
            )
        },
        {
            field: "isSelected",
            flex: 0.5,
            headerName: "",
            renderCell: (params) => (
                <Checkbox
                    checked={props.selectedRows.has(params.row.binName)}
                    disabled={props.isReadOnly}
                    onChange={() => props.onChangeIsRowSelectedCallback(params.row.binName)}
                    />
            )
        }
    ];

    return (
        <>
            <GenericDataGrid
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                initialStatePagination={{
                    page: 0,
                    pageSize: 10
                }}
                initialStateSorting={{
                    sortModel: [{ field: "binName", sort: "asc" }]
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                rows={mappedRows}
                />
        </>
    );
}

export { BinSelectionDataGrid }