import React from "react";
import { DeleteButton } from "../../base/crud";
import { useApiService } from "../../base/providers";

type AuditDeleteButtonProps = {
    deleteId: number
    disabled: boolean
    reloadDataCallback: () => void
}

function AuditDeleteButton(props: AuditDeleteButtonProps): JSX.Element {
    const { apiService } = useApiService();

    const deleteActionCallback = React.useCallback((deleteId: number): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await apiService.dsp.cancelAudit(deleteId);
                props.reloadDataCallback();
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }, [apiService, props]);

    return (
        <DeleteButton
            deleteAction={deleteActionCallback}
            deleteId={props.deleteId}
            disabled={props.disabled}
            />
    );
}

export { AuditDeleteButton }