import { Stack, Typography } from "@mui/material";
import { PrintForm, PrintPartsLabelFormValues } from "./form";
import { PrintTemplateResolver } from "../../base/print/printTemplateResolver";
import React from "react";
import { PrintableTemplateHandle } from "types/print/PrintableTemplateHandle";
import { useApiService } from "../../base/providers";
import { GetDspPrintInstructionsRespose, PartDetails } from "types/dsp/print";
import { BackToAudits } from "../audit/backToAuditsButton";

type PrintTemplateDetailsType = {
    partDetails: PartDetails[]
    printType: "bin" | "stock" | ""
}

function DspPrintView(): JSX.Element {
    const { apiService } = useApiService();
    const printTemplateRef = React.useRef<PrintableTemplateHandle>(null);
    const [printTemplateDetails, setPrintTemplateDetails] = React.useState<PrintTemplateDetailsType>({
        partDetails: [],
        printType: "stock"
    });

    const clickSubmitCallback = React.useCallback((formValues: PrintPartsLabelFormValues): void => {
        const stockNumbers = formValues.stockNumbersStr.split(",");
        const labelTypeNum = Number(formValues.labelType);
        apiService.dsp.generatePrintInstructions(stockNumbers, labelTypeNum)
            .then((getPrintInstructionsResponse: GetDspPrintInstructionsRespose) => setPrintTemplateDetails({
                partDetails: getPrintInstructionsResponse.foundStock,
                printType: formValues.labelType === "1"
                    ? "stock"
                    : formValues.labelType === "24"
                        ? "bin"
                        : ""
            }))
            .catch((err) => console.error("An error occurred while printing", err));
    }, [apiService]);

    React.useEffect(() => {
        if (!printTemplateDetails.partDetails || printTemplateDetails.partDetails.length === 0) {
            return;
        }

        setTimeout(() => {
            printTemplateRef.current?.print();
        }, 0);
    }, [printTemplateDetails]);
    
    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <BackToAudits />
            <Typography variant="h4">Print Labels</Typography>
            <PrintForm clickSubmitCallback={clickSubmitCallback} />
            <PrintTemplateResolver
                ref={printTemplateRef}
                partDetails={printTemplateDetails.partDetails}
                printType={printTemplateDetails.printType}
                foundStock={[]}
                />
        </Stack>
    );
}

export { DspPrintView }