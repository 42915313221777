import React from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DspPrinterType, DspPrinterTypeDef } from "../../../../types/dsp/printerTypes";

type PrinterTypeDropdownProps = {
    selectedType: string
    setSelectedTypeCallback: (value: string) => void
}

function PrinterTypeDropdown(props: PrinterTypeDropdownProps): JSX.Element {
    const { selectedType, setSelectedTypeCallback } = props;
    const [printerTypes, setPrinterTypes] = React.useState<DspPrinterTypeDef[]>([]);
    const [optionsElements, setOptionsElements] = React.useState<JSX.Element[]>([]);
    
    React.useEffect(() => {
        const allPrinterTypes = DspPrinterType.getAllTypes();
        setPrinterTypes(allPrinterTypes);
    }, []);

    React.useEffect(() => {
        if (!printerTypes) {
            return;
        }

        setOptionsElements(printerTypes.map((printerType) => (
            <MenuItem key={printerType.id} value={`${printerType.id}`}>
                {printerType.value}
            </MenuItem>
        )));
    }, [printerTypes]);

    const onChangeSelectedValue = (e: SelectChangeEvent): void => {
        e.preventDefault();

        setSelectedTypeCallback(e.target.value);
    }

    return (
        <FormControl>
            <InputLabel id="printer-type-dropdown-label">Printer Type</InputLabel>
            <Select
                id="printerType"
                labelId="printer-type-dropdown-label"
                label="Printer Type"
                onChange={onChangeSelectedValue}
                value={selectedType}
                >
                {optionsElements}
            </Select>
        </FormControl>
    );
}

export { PrinterTypeDropdown }