import { Button, Chip } from "@mui/material";
import { AuditVarianceTabType } from "../../varianceTypes";

type PendingTabButtonProps = {
    auditVarianceCount: number
    setAuditVarianceTypeCallback: (auditVarianceType: AuditVarianceTabType) => void
}

function PendingTabButton(props: PendingTabButtonProps) {
    const onClickPendingTabButton = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.setAuditVarianceTypeCallback("pending");
    }

    return (
        <Button onClick={onClickPendingTabButton}>
            Pending Variances&nbsp;
            <Chip
                color="primary"
                label={props.auditVarianceCount}
                />
        </Button>
    );
}

export { PendingTabButton }