import axios, { AxiosInstance } from "axios";
import { DspApiService } from "./dspApiService";
import { DsvApiService } from "./dsvApiService";
import { LocalNetworkService } from "./localNetworkService";

class ApiServiceContainer {
    static initialInstance: ApiServiceContainer = new ApiServiceContainer(process.env.REACT_APP_API_URL || "", "", "", "", "");

    private api: AxiosInstance;

    dsp: DspApiService;
    dsv: DsvApiService;
    localNetwork: LocalNetworkService;
    
    constructor(baseUrl: string, dealerId: string, locationId: string, username: string, accessToken: string) {
        this.api = axios.create({
            baseURL: baseUrl,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
                "x-dealer-id": dealerId,
                "x-location-id": locationId,
                "x-scangun-id": "DealerScan Web",
                "x-username": username
            }
        });

        this.dsp = new DspApiService(this.api);
        this.dsv = new DsvApiService(this.api);
        this.localNetwork = new LocalNetworkService();
    }
}

export { ApiServiceContainer }