import { Button, Stack } from "@mui/material";

type SelectionActionViewProps = {
    isReadOnly: boolean
    onClickPrimaryActionCallback: () => void
    onClickSecondaryActionCallback: () => void
}

function SelectionActionView(props: SelectionActionViewProps): JSX.Element {
    const onClickPrimaryAction = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.onClickPrimaryActionCallback();
    }

    const onClickSecondaryAction = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.onClickSecondaryActionCallback();
    }

    return (
        <Stack
            direction="row-reverse"
            spacing={2}
            >
            <Button
                onClick={onClickPrimaryAction}
                variant="contained"
                >
                { props.isReadOnly ? "Complete" : "Review Audit"}
            </Button>
            <Button
                onClick={onClickSecondaryAction}
                variant="outlined"
                >
                { props.isReadOnly ? "Go Back" : "Cancel"}
            </Button>
        </Stack>
    );
}

export { SelectionActionView }