import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";
import { AuthButton } from "./authButton";
import { useNavigate } from "react-router-dom";

function NavBar(): JSX.Element {
    const navigate = useNavigate();

    const onClickVehicles = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate("/vehicles");
    }

    const onClickParts = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        
        navigate("/parts");
    }

    return(
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        >
                        DealerBuilt Scan
                    </Typography>
                    <Stack
                        component={Box}
                        direction="row"
                        spacing={1}
                        sx={{
                            flexGrow: 1,
                            paddingLeft: 2
                        }}
                        >
                        <Button
                            color="inherit"
                            onClick={onClickVehicles}
                            variant="outlined"
                            >
                            Vehicles
                        </Button>
                        <Button
                            color="inherit"
                            onClick={onClickParts}
                            variant="outlined"
                            >
                            Parts
                        </Button>
                    </Stack>
                    <AuthButton
                        color="inherit"
                        variant="outlined"
                        />
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export { NavBar };