import React from "react";
import { DspAudit } from "../../../types/dsp/audits";
import { GenericDataGridFilterable, PreDefinedFilterModel } from "../../base/dataGrid/genericDataGridFilterable";
import { GridColDef } from "@mui/x-data-grid";
import { AuditDetailsButton } from "./auditDetailsButton";
import { AuditDeleteButton } from "./auditDeleteButton";
import { AuditStatusResolver } from "./auditStatusResolver";
import { Utilities } from "../../../utilities";

type AuditsDataGridProps = {
    audits: DspAudit[]
    reloadDataCallback: () => void
}

type MappedRow = {
    id: number
    auditName: string
    status: number
    totalItems: string
    totalAmount: number
    dateCreated: Date
    deleteId: number
    type: "open" | "finished"
}

const columns = (reloadDataCallback: () => void): GridColDef[] => [
    {
        field: "id",
        headerName: "ID",
        hideable: true,
        width: 90
    },
    {
        field: "auditName",
        flex: 1,
        headerName: "Audit Name",
        hideable: false,
        renderCell: (params) => (
            <AuditDetailsButton
                auditId={params.row.id}
                auditName={params.value}
                disabled={params.row.type === "finished"}
                />
        )
    },
    {
        field: "status",
        flex: 0.75,
        headerName: "Status",
        renderCell: (params) => (
            <AuditStatusResolver status={params.value} />
        )
    },
    {
        field: "totalItems",
        flex: 1,
        headerName: "Total Bins/Parts",
        hideable: false
    },
    {
        field: "totalAmount",
        flex: 1,
        headerName: "Total Audit Amount",
        hideable: false,
        renderCell: (params) => (
            <span>{Utilities.displayAsCurrency(params.value)}</span>
        )
    },
    {

        field: "dateCreated",
        flex: 1,
        headerName: "Date Created",
        hideable: false,
        renderCell: (params) => {
            const date = new Date(params.value);
            return (<span>{date.toLocaleString()}</span>);
        }
    },
    {
        field: "deleteId",
        filterable: false,
        headerName: "",
        hideable: false,
        renderCell: (params) => (
            <AuditDeleteButton
                deleteId={params.value}
                disabled={params.row.type === "finished"}
                reloadDataCallback={reloadDataCallback}
                />
        ),
        sortable: false,
        width: 100
    },
    {
        field: "type",
        filterable: false,
        flex: 1,
        hideable: true
    }
];

const predefinedFilters: PreDefinedFilterModel[] = [
    {
        label: "Open Audits",
        filterModel: {
            items: [
                { id: 1, field: "type", operator: "equals", value: "open" }
            ]
        }
    },
    {
        label: "Finished Audits",
        filterModel:{
            items: [
                { id: 2, field: "type", operator: "equals", value: "finished" }
            ]
        }
    }
];

function AuditsDataGrid(props: AuditsDataGridProps): JSX.Element {
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        const { audits } = props;

        if (!audits || audits.length === 0) {
            return;
        }
        
        setMappedRows(audits.map((audit) => {
            return {
                id: audit.id,
                auditName: audit.auditName,
                status: audit.status,
                totalItems: `${audit.binCount} Bins, ${audit.partCount} Parts`,
                totalAmount: audit.totalCost,
                dateCreated: audit.createdStamp,
                deleteId: audit.id,
                type: audit.status === 2 || audit.status === 3
                    ? "finished"
                    : "open"
            };
        }))
    }, [props]);
    
    return (
        <GenericDataGridFilterable
            columns={columns(props.reloadDataCallback)}
            filterModels={predefinedFilters}
            columnVisibilityModel={{
                id: false,
                type: false
            }}
            initialStatePagination={{
                page: 0,
                pageSize: 25
            }}
            initialStateSorting={{
                sortModel: [{ field: "dateCreated", sort: "desc" }]
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            rows={mappedRows}
            />
    );
}

export { AuditsDataGrid }