import React from "react";
import { Button, Stack, TextField } from "@mui/material";

type CreateAuditFormValues = {
    auditName: string
}

type CreateAuditFormsProps = {
    clickSubmitCallback: (formValues: CreateAuditFormValues) => void
}

function CreateAuditForm(props: CreateAuditFormsProps): JSX.Element {
    const [formValues, setFormValues] = React.useState<CreateAuditFormValues>({
        auditName: `Audit ${new Date().toLocaleDateString()}`
    });

    const onChangeAuditName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFormValues(prevState => ({
            ...prevState,
            auditName: e.target.value || ""
        }));
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.clickSubmitCallback(formValues);
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >
            <TextField
                id="auditName"
                label="Audit Name"
                onChange={onChangeAuditName}
                value={formValues.auditName}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Create
            </Button>
        </Stack>
    );
}

export { CreateAuditForm }
export type { CreateAuditFormValues }