import { Button, Chip } from "@mui/material";
import { PendingVarianceModal } from "./pendingVarianceModal";
import React from "react";
import { useApiService } from "../../../../../base/providers";
import { DspAuditVariance, DspAuditVarianceChange } from "types/dsp/audits";

type PendingVarianceButtonProps = {
    pendingVariances: DspAuditVariance[]
    reloadDataCallback: () => void
}

function AcceptPendingVarianceButton(props: PendingVarianceButtonProps): JSX.Element {
    const { apiService } = useApiService();
    const [showAcceptNewQuantitiesModal, setShowAcceptNewQuantitiesModal] = React.useState(false);
    
    const hideAcceptNewQuantitiesModalCallback = React.useCallback(() => {
        setShowAcceptNewQuantitiesModal(false);
    }, []);

    const onClickAcceptNewQuantityCallback = React.useCallback(() => {
        const mappedVariances = props.pendingVariances.map((value: DspAuditVariance): DspAuditVarianceChange => {
            // TODO DM 09/24/2024 Clean this up once we get the full workflow validating.
            return {
                auditBinId: value.bins[0].binId,
                lightyearId: value.lightyearPartId,
                newOnHand: value.scannedCount
            };
        }, []);
        apiService.dsp.updateBinVariance(mappedVariances)
            .then(() => setShowAcceptNewQuantitiesModal(false))
            .then(() => props.reloadDataCallback())
            .catch((err) => console.error("Unable to update bin variances", err));
    }, [apiService, props]);

    const onClickAcceptNewQuantity = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setShowAcceptNewQuantitiesModal(true);
    }

    return (
        <Button
            onClick={onClickAcceptNewQuantity}
            variant="outlined"
            >
            <Chip label={props.pendingVariances.length} />&nbsp;Accept New Quantity
            <PendingVarianceModal
                showModal={showAcceptNewQuantitiesModal}
                hideModalCallback={hideAcceptNewQuantitiesModalCallback}
                onClickConfirmCallback={onClickAcceptNewQuantityCallback}
                />
        </Button>
    );
}

export { AcceptPendingVarianceButton }