import { DspAudit } from "../../../types/dsp/audits";
import { NoExistingAuditsView } from "./noExistingAuditsView";
import { AuditsDataGrid } from "./auditsDataGrid";

type OngoingAuditsResolverProps = {
    audits: DspAudit[]
    getAllAuditsCallback: () => void
}

function OngoingAuditsResolver(props: OngoingAuditsResolverProps): JSX.Element {

    if (!props.audits || props.audits.length === 0) {
        return (
            <NoExistingAuditsView
                getAllAuditsCallback={props.getAllAuditsCallback}
                />
        );
    }

    return (
        <AuditsDataGrid
            audits={props.audits}
            reloadDataCallback={props.getAllAuditsCallback}
            />
    );
}

export { OngoingAuditsResolver }