import React from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { DspAuditVariance, GetDspAuditVarianceResponse } from "types/dsp/audits";
import { AuditVarianceTabType } from "./varianceTypes";
import { useApiService } from "../../../base/providers";
import { VarianceOptionsRow } from "./optionsRow";
import { VarianceTitleRow } from "./titleRow";
import { VarianceDataGridResolver } from "./dataGrid";

type AuditVarianceStateType = {
    auditVarianceType: AuditVarianceTabType
    auditName: string
    auditVariances: DspAuditVariance[]
    rescanVariances: DspAuditVariance[]
    pendingVariances: DspAuditVariance[]
}

function VarianceView(): JSX.Element {
    const { auditId } = useParams();
    const { apiService } = useApiService();
    const [auditVarianceState, setAuditVarianceState] = React.useState<AuditVarianceStateType>({
        auditVarianceType: "pending",
        auditName: "",
        auditVariances: [],
        rescanVariances: [],
        pendingVariances: []
    });

    const getAuditVariancesCallback = React.useCallback((): void => {
        if (!auditId) {
            return;
        }

        apiService.dsp.getAuditVariance(auditId)
            .then((response: GetDspAuditVarianceResponse) => {
                const auditVariances = response.auditVariances.filter((value: DspAuditVariance) => value.quantityVariance !== 0);

                setAuditVarianceState(prevState => {
                    return {
                        ...prevState,
                        auditName: response.audit.auditName,
                        auditVariances: auditVariances,
                    };
                });
            })
            .catch((err) => console.error("Unable to get audit", err));
    }, [apiService, auditId]);

    const setAuditVarianceTypeCallback = React.useCallback((type: AuditVarianceTabType): void => {
        setAuditVarianceState(prevState => {
            return {
                ...prevState,
                auditVarianceType: type
            };
        });
    }, []);

    const onChangeIsPendingVarianceSelectedCallback = React.useCallback((lightyearIds: Set<number>) => {
        setAuditVarianceState(prevState => {
            const newPendingVariances = prevState.auditVariances.filter((value: DspAuditVariance) => lightyearIds.has(value.lightyearPartId));
            return {
                ...prevState,
                pendingVariances: newPendingVariances,
            }
        });
    }, []);

    const onChangeIsRescanVarianceSelectedCallback = React.useCallback((lightyearIds: Set<number>) => {
        setAuditVarianceState(prevState => {
            return {
                ...prevState,
                rescanVariances: prevState.auditVariances.filter((value: DspAuditVariance) => lightyearIds.has(value.lightyearPartId)),
            }
        });
    }, []);

    React.useEffect(() => {
        getAuditVariancesCallback();
    }, [getAuditVariancesCallback]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <VarianceTitleRow
                auditId={auditId || ""}
                auditName={auditVarianceState.auditName}
                />
            <VarianceOptionsRow
                auditVariances={auditVarianceState.auditVariances}
                auditVarianceType={auditVarianceState.auditVarianceType}
                onChangeIsPendingRowSelectedCallback={onChangeIsPendingVarianceSelectedCallback}
                onChangeIsRescanRowSelectedCallback={onChangeIsRescanVarianceSelectedCallback}
                pendingVariances={auditVarianceState.pendingVariances}
                reloadDataCallback={getAuditVariancesCallback}
                rescanVariances={auditVarianceState.rescanVariances}
                setAuditVarianceTypeCallback={setAuditVarianceTypeCallback}
                />
            <VarianceDataGridResolver
                auditVariances={auditVarianceState.auditVariances}
                auditVarianceType={auditVarianceState.auditVarianceType}
                onChangeIsPendingRowSelectedCallback={onChangeIsPendingVarianceSelectedCallback}
                onChangeIsRescanRowSelectedCallback={onChangeIsRescanVarianceSelectedCallback}
                rescanVariances={auditVarianceState.rescanVariances}
                />
        </Stack>
    );
}

export { VarianceView }