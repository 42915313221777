import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { GenericDataGrid } from "../../../../../base/dataGrid/genericDataGrid";
import { DspAuditBinSimple, DspAuditVariance } from "types/dsp/audits";
import { Checkbox, Input } from "@mui/material";
import { Utilities } from "../../../../../../utilities";

type PendingVarianceDataGridProps = {
    auditVariances: DspAuditVariance[]
    onChangeIsRowSelectedCallback: (selectedLightyearIds: Set<number>) => void
}

type MappedRow = {
    id: number
    partNumber: string
    bins: DspAuditBinSimple[]
    vendor: string
    dollarVariance: number
    quantityVariance: number
    scannedCount: number
    scannedBy: string
    scanDate: Date
    partsMovement: number
    isSelected: boolean
}

type ColumnParamsType = {
    selectedRows: Set<number>
    onChangeIsRowSelectedCallback: (lightyearId: number) => void
}

const columns = (columnParams: ColumnParamsType): GridColDef[] => [
    {
        field: "id",
        flex: 1,
        headerName: "ID",
        hideable: true
    },
    {
        field: "isSelected",
        flex: 0.5,
        headerName: "Select",
        hideable: false,
        renderCell: (params) => (
            <Checkbox
                checked={columnParams.selectedRows.has(params.row.id)}
                onChange={() => columnParams.onChangeIsRowSelectedCallback(params.row.id)}
                />
        )
    },
    {
        field: "partNumber",
        flex: 1,
        headerName: "Part Number",
        hideable: false
    },
    {
        field: "bins",
        flex: 0.5,
        headerName: "Bin",
        hideable: false,
        renderCell: (params) => (
            <span>{params.value.map((bin: DspAuditBinSimple) => bin.binName).join(", ")}</span>
        )
    },
    {
        field: "vendor",
        flex: 0.5,
        headerName: "Vendor",
        hideable: false
    },
    {
        field: "dollarVariance",
        flex: 0.5,
        headerName: "$ Variance",
        hideable: false,
        renderCell: (params) => (
            <span>{Utilities.displayAsCurrency(params.value)}</span>
        )
    },
    {

        field: "quantityVariance",
        flex: 0.5,
        headerName: "Qty Variance",
        hideable: false
    },
    {
        field: "scannedCount",
        flex: 0.5,
        headerName: "Scanned",
        hideable: false
    },
    {
        field: "scannedBy",
        flex: 1,
        headerName: "Scanned By",
        hideable: true
    },
    {
        field: "scanDate",
        flex: 1,
        headerName: "Scan Date",
        hideable: true
    },
    {
        field: "partsMovement",
        flex: 0.5,
        headerName: "Movement",
        hideable: false
    },
    {
        field: "newQty",
        flex: 0.5,
        headerName: "New Qty",
        hideable: false,
        renderCell: (params) => {
            return (
                <Input
                    value={params.row.scannedCount}
                    />
            )
        }
    },
    {
        field: "",
        flex: 2,
        headerName: "",
        hideable: false
    }
];

function PendingVarianceDataGrid(props: PendingVarianceDataGridProps): JSX.Element {
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);
    const [selectedRows, setSelectedRows] = React.useState<Set<number>>(new Set<number>());

    const onChangeIsRowSelectedCallback = React.useCallback((lightyearId: number) => {
        setSelectedRows((prevState) => {
            const newSelected = new Set(prevState);

            if (newSelected.has(lightyearId)) {
                newSelected.delete(lightyearId);
            } else {
                newSelected.add(lightyearId);
            }

            return newSelected
        });
    }, []);

    React.useEffect(() => {
        if (!props.auditVariances) {
            return;
        }

        setMappedRows(props.auditVariances.map((auditVariance: DspAuditVariance) => {
            return {
                id: auditVariance.lightyearPartId,
                partNumber: auditVariance.partNumber,
                bins: auditVariance.bins,
                vendor: auditVariance.vendor,
                dollarVariance: auditVariance.dollarVariance,
                quantityVariance: auditVariance.quantityVariance,
                scannedCount: auditVariance.scannedCount,
                scannedBy: auditVariance.auditorName,
                scanDate: auditVariance.auditDate,
                partsMovement: auditVariance.movementSinceScan,
                isSelected: false
            };
        }));
    }, [props.auditVariances]);

    React.useEffect(() => {
        props.onChangeIsRowSelectedCallback(selectedRows);
    }, [selectedRows]);

    return (
        <GenericDataGrid
            columns={columns({
                selectedRows: selectedRows,
                onChangeIsRowSelectedCallback: onChangeIsRowSelectedCallback
            })}
            columnVisibilityModel={{
                id: false,
                scannedBy: false,
                scanDate: false
            }}
            rows={mappedRows}
            />
    );
}

export { PendingVarianceDataGrid }